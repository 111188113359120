import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useEffect } from "react";

export function useApiEffect<T>(
    {
        data,
        error,
    }: {
        data?: T;
        error?: FetchBaseQueryError | SerializedError;
    },
    {
        onErr,
        onOk,
    }: {
        onErr?: (err: FetchBaseQueryError | SerializedError) => void;
        onOk?: (data: T) => void;
    }
) {
    useEffect(() => {
        if (error === undefined) return;
        onErr?.(error);
    }, [error]);

    useEffect(() => {
        if (data === undefined) return;
        onOk?.(data);
    }, [data]);
}
