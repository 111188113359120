import useTranslation from "@/i18n";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import {
    WantToListen,
    useGetAudiobooksQuery,
    useGetUsersByIdWantToListenQuery, usePutUsersByIdWantToListenMutation
} from "@/api/generatedApi";
import React, {useEffect, useState} from "react";
import { setErrorNotification } from "@/store/notificationSlice";
import { ErrorComponent } from "@/components/util/ErrorComponent";
import { LoadingView } from "@/views/ErrorView";
import { HistoryBookCard } from "@/views/history/components/HistoryBookCard";
import {SortableList} from "@/components/SortableList";

export default function WantToListenView() {
    const t = useTranslation();

    const dispatch = useDispatch();

    const [wantToListenItems, setWantToListenListItems] = useState<WantToListen[]>([]);

    const loggedInUser = useSelector(selectUser)!!;

    const { data: audiobooks, error: audiobookError } = useGetAudiobooksQuery();
    const { data: wantToListenData, error: wantToListenError } = useGetUsersByIdWantToListenQuery({ id: loggedInUser.id});
    const [updateWantToListenPositions] = usePutUsersByIdWantToListenMutation();

    useEffect(() => {
        if (audiobookError !== undefined) {
            dispatch(setErrorNotification({ text: "Loading Audiobooks failed" }));
        }
    }, [audiobookError]);

    useEffect(() => {
        if (wantToListenError !== undefined) {
            dispatch(setErrorNotification({ text: "Loading Want To Listen list failed" }));
        }
    }, [wantToListenError]);

    useEffect(() => {
        if (wantToListenData === undefined) {return}
        setWantToListenListItems(wantToListenData);
    }, [wantToListenData]);

    if (audiobookError) return <ErrorComponent error={audiobookError} />;
    if (!audiobooks || !wantToListenData) return <LoadingView description={"loading"} />;

    const audiobookMap = new Map(audiobooks.map((a) => [a.id, a]));

    function handleOnChange(newItems: WantToListen[]) {
        const data = [];
        for (let i = 0; i < newItems.length; i++) {
            data.push({
                id: newItems[i].id,
                position: i + 1,
            });
        }
        updateWantToListenPositions({id: loggedInUser.id, body: data});
        setWantToListenListItems(newItems);
    }

    return (
        <div className="max-w-5xl mx-auto m-3 px-1.5">
            <div className="flex justify-center">
                <h1 className="text-3xl mb-5">{t("navBarWantToListen")}</h1>
            </div>

            <div>
                <SortableList
                    items={wantToListenItems}
                    onChange={handleOnChange}
                    getIdxFunc={(item: WantToListen, idx: number) => item.position === idx}
                    renderItem={(wantToListenItem) => (
                        <SortableList.Item id={wantToListenItem.position}>
                            <div className="flex flex-row">
                                <div className="m-auto sm:text-2xl text-lg w-16">
                                    <div className="m-0">{wantToListenItem.position}</div>
                                </div>
                                <div className="w-full mr-3">
                                    <HistoryBookCard audiobook={audiobookMap.get(wantToListenItem.audiobookId)!!}/>
                                </div>
                                    <SortableList.DragHandle/>
                            </div>
                        </SortableList.Item>
                    )}
                />
            </div>
        </div>
    );
}
