import { MB, Minute, Second } from "@/utils/constant";
import { formatDataSize, formatDecimals, formatDuration } from "@/utils/format";
import { KeyValueStore, Store, useKeyValueStore } from "@/utils/kvStore";
import { classNames } from "@/utils/util";
import React, { useRef, useState, useEffect, useMemo } from "react";
import { getAudiobookDownloadUrl } from "../Audiobook";
import useTranslation from "@/i18n";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import { setNotification } from "@/store/notificationSlice";
import { Progressbar } from "@/components/Progressbar";
import { FetchProgress, useFetchProgress } from "@/utils/fetchWithProgress";
import { BackwardIcon } from "@/components/icons/BackwardIcon";
import { PauseIcon } from "@/components/icons/PauseIcon";
import { PlayIcon } from "@/components/icons/PlayIcon";
import { ForwardIcon } from "@/components/icons/ForwardIcon";
import { PlaybackSpeedIcon } from "@/components/icons/PlabackSpeedIcon";
import { SleepTimerIcon } from "@/components/icons/SleepTimerIcon";
import { DeleteIcon } from "@/components/icons/DeleteIcon";
import { LoadingIcon } from "@/components/icons/LoadingIcon";
import { DownloadIcon } from "@/components/icons/DownloadIcon";
import { useWakeLock } from "@/utils/wakelockHook";
import { Audiobook } from "@/api/generatedApi";
import { useImageHook } from "@/utils/imageHook";

type PlaybackInfo = {
    currentTimeMs: number;
    playbackSpeedOption: number;
    volume: number;
};

function LoadedPlayer({ audiobook, srcUrl, removeDownload }: { audiobook: Audiobook; srcUrl: string; removeDownload: () => void }) {
    const audioRef = useRef<HTMLAudioElement | null>(null);

    // player state
    const [isPlaying, setIsPlaying] = useState(false);
    useEffect(() => {
        if (!audioRef.current) return;
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }

        if ("mediaSession" in navigator) {
            navigator.mediaSession.playbackState = isPlaying ? "playing" : "paused";
        }
    }, [isPlaying]);

    // player time
    const [duration, setDuration] = useState(0);
    const handleLoadedMetadata = () => !!audioRef.current && setDuration(audioRef.current.duration);
    const [currentTimeInSec, setCurrentTimeInSec] = useState(0);
    const handleTimeUpdate = () => !!audioRef.current && setCurrentTimeInSec(audioRef.current.currentTime);
    const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => !!audioRef.current && (audioRef.current.currentTime = parseFloat(e.target.value));

    // forward/backward
    const skipTimeForward = 30 * Second;
    const handleForward = (amount: number = skipTimeForward) => {
        if (!audioRef.current) return;
        audioRef.current.currentTime = Math.min(audioRef.current.duration, audioRef.current.currentTime + amount / Second);
    };
    const skipTimeBackward = 10 * Second;
    const handleBackward = (amount: number = skipTimeBackward) => {
        if (!audioRef.current) return;
        audioRef.current.currentTime = Math.max(0, audioRef.current.currentTime - amount / Second);
    };

    // playback speed
    const playbackSpeedOptions = [1, 1.25, 1.5, 1.75, 2];
    const [playbackSpeedOption, setPlaybackSpeedOption] = useState(0);
    const togglePlaybackSpeed = () => setPlaybackSpeedOption((prevOption) => (prevOption + 1) % playbackSpeedOptions.length);
    useEffect(() => {
        if (!audioRef.current) return;
        audioRef.current.playbackRate = playbackSpeedOptions[playbackSpeedOption];
    }, [playbackSpeedOption]);

    // sleep timer
    const [runningSleepTimer, setRunningSleepTimer] = useState<number | null>(null);
    useEffect(() => {
        if (runningSleepTimer === null) return;

        const timerInterval = setInterval(() => {
            setRunningSleepTimer((prevTimer) => {
                if (prevTimer === null || prevTimer <= 1) {
                    setIsPlaying(false);
                    clearInterval(timerInterval);
                    return null;
                }
                return prevTimer - Minute;
            });
        }, Minute);

        // Cleanup interval on component unmount
        return () => clearInterval(timerInterval);
    }, [runningSleepTimer]);

    const sleepTimerOptions = [0, 5, 10, 15, 30, 45, 60];
    const [sleepTimerOption, setSleepTimerOption] = useState(0);
    const toggleSleepTimer = () => {
        const newSleepTimer = (sleepTimerOption + 1) % sleepTimerOptions.length;
        setSleepTimerOption((prev) => {
            const newOption = (prev + 1) % sleepTimerOptions.length;

            if (newOption === 0) {
                setRunningSleepTimer(null);
                return newOption;
            }

            setRunningSleepTimer(sleepTimerOptions[newSleepTimer] * Minute);
            return newOption;
        });
    };

    // save playback info
    const [playbackInfo, setPlaybackInfo, deletePlaybackInfo] = useKeyValueStore<PlaybackInfo>("player-state", audiobook.id);
    useEffect(() => {
        const newPbInfo: PlaybackInfo = {
            currentTimeMs: currentTimeInSec * Second,
            playbackSpeedOption: playbackSpeedOption,
            volume: audioRef.current?.volume ?? 1,
        };
        setPlaybackInfo(newPbInfo);
    }, [currentTimeInSec, isPlaying, playbackSpeedOption]);

    // load playback info
    const [loadedInitialPlaybackInfo, setLoadedInitialPlaybackInfo] = useState(false);
    useEffect(() => {
        if (!loadedInitialPlaybackInfo && playbackInfo && audioRef.current) {
            setLoadedInitialPlaybackInfo(true);
            audioRef.current.currentTime = playbackInfo.currentTimeMs / Second;
            audioRef.current.playbackRate = playbackSpeedOptions[playbackInfo.playbackSpeedOption] || 1;
            setCurrentTimeInSec(playbackInfo.currentTimeMs / Second);
            setPlaybackSpeedOption(playbackInfo.playbackSpeedOption);
        }
    }, [playbackInfo, loadedInitialPlaybackInfo]);

    // keyboard shortcuts
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === " ") {
            e.preventDefault();
            setIsPlaying((prevIsPlaying) => !prevIsPlaying);
        }
        if (e.key === "m") {
            if (!audioRef.current) return;
            e.preventDefault();
            audioRef.current.muted = !audioRef.current.muted;
        }
        if (e.key === "ArrowRight") {
            e.preventDefault();
            handleForward();
        }
        if (e.key === "ArrowLeft") {
            e.preventDefault();
            handleBackward();
        }
    };
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, []);

    const smallImage = useImageHook(audiobook.id, 128);
    const bigImage = useImageHook(audiobook.id, 512);

    // mobile card
    useEffect(() => {
        const audio = audioRef.current;
        if (!audio || !("mediaSession" in navigator)) return;

        navigator.mediaSession.metadata = new MediaMetadata({
            title: audiobook.name,
            artist: audiobook.authors.map((a) => a.name).join(", "),
            // album: series
            artwork: [
                { src: smallImage, sizes: "128x128" },
                { src: bigImage, sizes: "512x512" },
            ],
        });

        navigator.mediaSession.setActionHandler("play", () => setIsPlaying(true));
        navigator.mediaSession.setActionHandler("pause", () => setIsPlaying(false));
        navigator.mediaSession.setActionHandler("seekbackward", (details) =>
            handleBackward(details.seekOffset ? details.seekOffset * Second : skipTimeBackward)
        );
        navigator.mediaSession.setActionHandler("seekforward", (details) => handleForward(details.seekOffset ? details.seekOffset * Second : skipTimeForward));
        navigator.mediaSession.setActionHandler("seekto", (details) => {
            if (!audioRef.current) return;
            if (details.fastSeek && "fastSeek" in audioRef.current!) {
                audioRef.current.fastSeek(details.seekTime!);
            } else {
                audioRef.current.currentTime = details.seekTime!;
            }
        });
    }, [smallImage, bigImage]);

    const removeDownloadAndResetState = async () => {
        setIsPlaying(false);
        setCurrentTimeInSec(0);
        setDuration(0);
        setPlaybackSpeedOption(0);
        setRunningSleepTimer(null);
        removeDownload();
        await Promise.all([deletePlaybackInfo()]);
    };

    const audioPlayerOnLoad = () => {
        if (!audioRef.current) return;
        audioRef.current.currentTime = currentTimeInSec;
        audioRef.current.playbackRate = playbackSpeedOptions[playbackSpeedOption];
    };

    return (
        <div className="text-white w-full space-y-4">
            <audio
                ref={audioRef}
                onLoadedData={audioPlayerOnLoad}
                src={srcUrl}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                className="hidden"
            ></audio>

            <div className="grow">
                <input
                    type="range"
                    min="0"
                    max={duration.toString()}
                    value={currentTimeInSec.toString()}
                    step="0.1"
                    onChange={handleSeek}
                    className="w-full h-2 bg-gray-500 dark:bg-gray-700 rounded-lg appearance-none cursor-pointer"
                />

                <div className="flex justify-between text-sm text-gray-400">
                    <span>{formatDuration(currentTimeInSec * Second, "HH:MM:SS")}</span>
                    <span>{formatDuration(duration * Second, "HH:MM:SS")}</span>
                </div>
            </div>

            <div className="flex justify-between gap-2 items-center flex-wrap">
                <button
                    onClick={() => handleBackward()}
                    className={`px-4 py-2 rounded-lg font-semibold border flex items-center justify-center text-gray-500 hover:text-gray-400 border-gray-500 hover:border-gray-400 shadow-md hover:shadow-none`}
                >
                    <BackwardIcon seconds={skipTimeBackward / Second} />
                </button>

                <button
                    onClick={() => setIsPlaying(!isPlaying)}
                    className={classNames(
                        `px-4 py-2 rounded-lg font-semibold hover:bg-opacity-80 border shadow-md hover:shadow-none`,
                        isPlaying ? "border-blue-500 text-blue-500" : "border-green-500 text-green-500"
                    )}
                >
                    {isPlaying ? <PauseIcon /> : <PlayIcon />}
                </button>

                <button
                    onClick={() => handleForward()}
                    className={`px-4 py-2 rounded-lg font-semibold border flex items-center justify-center text-gray-500 hover:text-gray-400 border-gray-500 hover:border-gray-400 shadow-md hover:shadow-none`}
                >
                    <ForwardIcon seconds={skipTimeForward / Second} />
                </button>

                <button
                    onClick={togglePlaybackSpeed}
                    className={classNames(
                        `px-4 py-2 rounded-lg font-semibold border flex items-center justify-center`,
                        playbackSpeedOption !== 0
                            ? "text-blue-500 hover:text-blue-400 border-blue-500 hover:border-blue-400 shadow-md hover:shadow-none"
                            : "text-gray-500 hover:text-gray-400 border-gray-500 hover:border-gray-400 shadow-md hover:shadow-none"
                    )}
                >
                    <PlaybackSpeedIcon factor={playbackSpeedOption !== 0 ? audioRef?.current?.playbackRate : undefined} />
                </button>

                <button
                    onClick={toggleSleepTimer}
                    className={classNames(
                        `px-4 py-2 rounded-lg font-semibold border flex items-center justify-center`,
                        !!runningSleepTimer
                            ? "text-orange-500 hover:text-orange-400 border-orange-500 hover:border-orange-400 shadow-md hover:shadow-none"
                            : "text-gray-500 hover:text-gray-400 border-gray-500 hover:border-gray-400 shadow-md hover:shadow-none"
                    )}
                >
                    <SleepTimerIcon minutes={runningSleepTimer ? runningSleepTimer / Minute : undefined} />
                </button>

                <button
                    onClick={removeDownloadAndResetState}
                    className={
                        "px-4 py-2 rounded-lg font-semibold border text-red-500 hover:text-red-400 border-red-500 hover:border-red-400 shadow-md hover:shadow-none"
                    }
                >
                    <DeleteIcon />
                </button>
            </div>
        </div>
    );
}

function DownloadPlayer({ audiobookId, onDownloaded }: { audiobookId: string; onDownloaded: () => void }) {
    const t = useTranslation();
    const dispatch = useDispatch();
    const loggedInUser = useSelector(selectUser)!;
    const [progress, setProgress] = useState<FetchProgress>({
        downloaded: 0,
        total: null,
        loading: false,
        error: null,
    });

    useWakeLock(progress.loading);

    // downloading
    const store = new KeyValueStore("audiobooks-content");
    const downloadAndStoreFile = async () => {
        try {
            const downloadUrl = await getAudiobookDownloadUrl(loggedInUser.sessionToken, audiobookId);
            await fetchWithChunkedProgress(downloadUrl, store, audiobookId, setProgress);
            onDownloaded();
        } catch (err) {
            dispatch(
                setNotification({
                    title: t("download failed"),
                    text: t("failed to download audiobook"),
                    type: "error",
                    duration: 4 * Second,
                })
            );
            console.error("Error during download and storage:", err);
        }
    };

    const progressInPercentage = useMemo(() => (progress.total ? (progress.downloaded / progress.total) * 100 : null), [progress]);
    const progressBarColor = useMemo(() => {
        if (progressInPercentage === null) return "bg-gray-500/80";
        if (progressInPercentage < 40) return "bg-red-500/80";
        if (progressInPercentage < 70) return "bg-yellow-500/80";
        return "bg-green-500/80";
    }, [progressInPercentage]);

    return (
        <div className="text-white w-full space-y-4">
            <div className="grow">
                <Progressbar percentage={progressInPercentage} color={progressBarColor} />

                <div className="flex justify-between text-sm text-gray-400">
                    <span>{formatDataSize(progress.downloaded)}</span>
                    <span>{progress.total ? formatDataSize(progress.total) : "-"}</span>
                </div>
            </div>

            <div className="flex justify-between gap-2 items-center flex-wrap">
                <button
                    disabled
                    className={`px-4 py-2 rounded-lg font-semibold border flex items-center justify-center text-gray-500 border-gray-500 opacity-50 cursor-not-allowed`}
                >
                    <BackwardIcon />
                </button>

                {progress.loading ? (
                    <div className={`px-4 py-2 rounded-lg font-semibold border flex items-center justify-center text-gray-500 border-gray-500`}>
                        <LoadingIcon />
                    </div>
                ) : (
                    <button
                        onClick={downloadAndStoreFile}
                        className={`px-4 py-2 rounded-lg font-semibold hover:bg-opacity-80 border border-blue-500 text-blue-500 shadow-md hover:shadow-none`}
                    >
                        <DownloadIcon />
                    </button>
                )}

                <button
                    disabled
                    className={`px-4 py-2 rounded-lg font-semibold border flex items-center justify-center text-gray-500 border-gray-500 opacity-50 cursor-not-allowed`}
                >
                    <ForwardIcon />
                </button>

                <button
                    disabled
                    className={`px-4 py-2 rounded-lg font-semibold border flex items-center justify-center text-gray-500 border-gray-500 opacity-50 cursor-not-allowed`}
                >
                    <PlaybackSpeedIcon />
                </button>

                <button
                    disabled
                    className={`px-4 py-2 rounded-lg font-semibold border flex items-center justify-center text-gray-500 border-gray-500 opacity-50 cursor-not-allowed`}
                >
                    <SleepTimerIcon />
                </button>

                <button disabled className={"px-4 py-2 rounded-lg font-semibold border text-gray-500 border-gray-500 opacity-50 cursor-not-allowed"}>
                    <DeleteIcon />
                </button>
            </div>
        </div>
    );
}

export function AudioPlayer({ audiobook }: { audiobook: Audiobook }) {
    const [srcUrl, setSrcUrl] = useState<string | null>(null);

    const store = new KeyValueStore("audiobooks-content");
    const loadBlob = async () => {
        const chunks: Blob[] = [];
        let chunkIndex = 0;

        while (true) {
            const chunk = await store.get<Blob>(`${audiobook.id}-chunk-${chunkIndex}`);
            if (!chunk) break; // Stop when no more chunks are found
            chunks.push(chunk);
            chunkIndex++;
        }
        if (chunks.length === 0) {
            setSrcUrl(null);
            return;
        }

        const blob = new Blob(chunks, { type: "audio/mpeg" });
        setSrcUrl(URL.createObjectURL(blob));
    };
    useEffect(() => {
        loadBlob();
    }, []);

    const deleteBlob = async () => {
        let chunkIdx = 0;
        while (true) {
            const chunk = await store.pop<Blob>(`${audiobook.id}-chunk-${chunkIdx}`);
            if (!chunk) break;
        }
        setSrcUrl(null);
    };

    if (!srcUrl) {
        return <DownloadPlayer audiobookId={audiobook.id} onDownloaded={loadBlob} />;
    }
    return <LoadedPlayer audiobook={audiobook} srcUrl={srcUrl} removeDownload={deleteBlob} />;
}

const CHUNK_SIZE = 100 * MB;

async function fetchWithChunkedProgress(url: string, store: KeyValueStore, key: string, onProgress: (progress: FetchProgress) => void) {
    onProgress({ downloaded: 0, total: null, loading: true, error: null });

    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentLength = response.headers.get("Content-Length");
        const total = contentLength ? parseInt(contentLength, 10) : null;

        const reader = response.body?.getReader();
        if (!reader) throw new Error("ReadableStream not supported");

        let downloaded = 0;
        let buffer: Uint8Array[] = [];
        let chunkIndex = 0;

        while (true) {
            const { done, value } = await reader.read();

            if (done) {
                if (buffer.length > 0) {
                    // Save the remaining bytes as the last chunk
                    const lastChunk = new Blob(buffer);
                    await store.set(`${key}-chunk-${chunkIndex}`, lastChunk);
                }
                break;
            }

            if (value) {
                buffer.push(value);
                downloaded += value.length;

                if (buffer.reduce((acc, arr) => acc + arr.length, 0) >= CHUNK_SIZE) {
                    // Save the current chunk to IndexedDB
                    const chunk = new Blob(buffer);
                    await store.set(`${key}-chunk-${chunkIndex}`, chunk);
                    chunkIndex++;
                    buffer = []; // Reset the buffer for the next chunk
                }

                // Update progress
                onProgress({
                    downloaded,
                    total,
                    loading: true,
                    error: null,
                });
            }
        }

        onProgress({ downloaded, total, loading: false, error: null });
    } catch (error: any) {
        onProgress({ downloaded: 0, total: null, loading: false, error: error.message });
        throw error;
    }
}
