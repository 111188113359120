import { Translation } from "./index";

export type AudiobookTranslationKey =
    | "book"
    | "books"
    | "audiobook"
    | "audiobooks"
    | "audiobookName"
    | "audiobookAuthor"
    | "audiobookNarrator"
    | "audiobookLanguage"
    | "audiobookLength"
    | "audiobookLengthMSec"
    | "audiobookPublicationDate"
    | "audiobookAddedOn"
    | "audiobookDescription"
    | "audiobookDownloadBtn"
    | "audiobookRefresh"
    | "audiobookMarkFinished"
    | "audiobookMarkUnfinished"
    | "audiobookMarkInterested"
    | "audiobookMarkIgnore"
    | "audiobookGetToken"
    | "audiobookOnAudible"
    | "audiobookAdd"
    | "audiobookAudiobookEdit"
    | "play"
    | "audiobookDurationSeconds"
    | "audiobookDurationMinutes"
    | "audiobookDurationHours"
    | "link to audiobook"
    | "link copied"
    | "the link to this audiobook has been copied to your clipboard."
    | "download"
    | "remove download"
    | "download failed"
    | "failed to download audiobook"
    | "downloading audiobook"
    | "player"
    | "download audiobook"
    | "addToWantToListen"
    | "removeFromWantToListen"

export const AUDIOBOOK_TRANSLATION: Translation<AudiobookTranslationKey> = {
    english: {
        play: "play",
        book: "Book",
        books: "Books",
        audiobook: "Audiobook",
        audiobooks: "Audiobooks",
        audiobookName: "Name",
        audiobookAuthor: "Author",
        audiobookNarrator: "Narrator",
        audiobookLanguage: "Language",
        audiobookLength: "Length",
        audiobookLengthMSec: "Length (in ms)",
        audiobookPublicationDate: "Published On",
        audiobookAddedOn: "Added On",
        audiobookDescription: "Description",
        audiobookDownloadBtn: "Download",
        audiobookRefresh: "Refresh",
        audiobookMarkFinished: "Mark Finished",
        audiobookMarkUnfinished: "Mark Unfinished",
        audiobookMarkInterested: "Interested",
        audiobookMarkIgnore: "Ignore",
        audiobookGetToken: "Get Token",
        audiobookOnAudible: "Open on Audible",
        audiobookAdd: "Add Audiobook",
        audiobookAudiobookEdit: "Edit Audiobook",
        audiobookDurationSeconds: "Seconds",
        audiobookDurationMinutes: "Minutes",
        audiobookDurationHours: "Hours",
        "link to audiobook": "Link to Audiobook",
        "link copied": "Link copied",
        "the link to this audiobook has been copied to your clipboard.": "The link to this audiobook has been copied to your clipboard.",
        download: "Download",
        "remove download": "Remove Download",
        "download failed": "Download failed",
        "failed to download audiobook": "Failed to download audiobook",
        "downloading audiobook": "Downloading Audiobook",
        player: "Player",
        "download audiobook": "Download Audiobook",
        addToWantToListen: "Want To Listen",
        removeFromWantToListen: "Remove Want To Listen"
    },
    german: {
        play: "abspielen",
        book: "Buch",
        books: "Bücher",
        audiobook: "Hörbuch",
        audiobooks: "Hörbücher",
        audiobookName: "Name",
        audiobookAuthor: "Autor",
        audiobookNarrator: "Erzähler",
        audiobookLanguage: "Sprache",
        audiobookLength: "Länge",
        audiobookLengthMSec: "Länge (in ms)",
        audiobookPublicationDate: "Veröffentlicht am",
        audiobookAddedOn: "Hinzugefügt am",
        audiobookDescription: "Beschreibung",
        audiobookDownloadBtn: "Herunterladen",
        audiobookRefresh: "Aktualisieren",
        audiobookMarkFinished: "Als beendet markieren",
        audiobookMarkUnfinished: "Als neu markieren",
        audiobookMarkInterested: "Interessiert",
        audiobookMarkIgnore: "Ignorieren",
        audiobookGetToken: "Download Token holen",
        audiobookOnAudible: "Audible",
        audiobookAdd: "Hörbuch hinzufügen",
        audiobookAudiobookEdit: "Hörbuch bearbeiten",
        audiobookDurationSeconds: "Sekunden",
        audiobookDurationMinutes: "Minuten",
        audiobookDurationHours: "Stunden",
        "link to audiobook": "Link zum Hörbuch",
        "link copied": "Link kopiert",
        "the link to this audiobook has been copied to your clipboard.": "Der Link zu diesem Hörbuch wurde in die Zwischenablage kopiert.",
        download: "Herunterladen",
        "remove download": "Download entfernen",
        "download failed": "Download fehlgeschlagen",
        "failed to download audiobook": "Hörbuch konnte nicht heruntergeladen werden",
        "downloading audiobook": "Hörbuch wird heruntergeladen",
        player: "Player",
        "download audiobook": "Hörbuch herunterladen",
        addToWantToListen: "Möchte ich hören",
        removeFromWantToListen: "Entfernen von Möchte ich hören"
    },
};
