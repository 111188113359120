import useTranslation, {ALL_LANGUAGES, TranslationKey} from "@/i18n";
import { Audiobook, useGetAudiobooksQuery } from "@/api/generatedApi";
import { ErrorComponent } from "@/components/util/ErrorComponent";
import { LoadingView } from "@/views/ErrorView";
import React, {useState} from "react";
import { useImageHook } from "@/utils/imageHook";
import { formatDate, formatDateWeeksAgo } from "@/utils/util";
import { Link } from "react-router-dom";
import {inputCls} from "@/styles";

function NewBookCard(props: { book: Audiobook }) {
    const t = useTranslation();

    const { book } = props;
    const imgSrc = useImageHook(book.id, 512);

    const weeks = formatDateWeeksAgo(new Date(Date.parse(book.timeCreated)));
    return (
        <div className="w-64 rounded overflow-hidden bg-white dark:bg-slate-700 shadow-lg hover:ring-gray-400 hover:ring-2">
            <Link className="" to={`/audiobooks/${book.id}`}>
                <div className="h-64">
                    <img className="object-fill w-full" src={imgSrc} alt="Cover" />
                </div>
                <div className="px-2 py-2">
                    <div className="font-bold text-xl mb-1 text-ellipsis">{book.name}</div>
                    <p>{formatDate(new Date(Date.parse(book.timeCreated)), false)}</p>
                    <p className="text-xs px-3 py-1 mt-2 rounded-full bg-gray-200 dark:bg-slate-500 text-gray-700 dark:text-white inline-block">
                        {weeks} {weeks != 1 ? t("weeksAgo") : t("weekAgo")}
                    </p>
                </div>
            </Link>
        </div>
    );
}

export function NewBooksComponent() {
    const t = useTranslation();

    const [languageFilter, setLanguageFilter] = useState<string>("any");

    const { data: audiobooks, error: audiobooksError } = useGetAudiobooksQuery();

    if (audiobooksError) return <ErrorComponent error={audiobooksError} />;

    if (!audiobooks) return <LoadingView description={t("loading")} />;
    if (audiobooks.length == 0) return <div>Library is currently Empty.</div>;

    let languages = new Set<string>(["any"]);
    for (const book of audiobooks) {
        languages.add(book.language);
    }

    let audiobooksSorted = [...audiobooks].sort((b, a) => Date.parse(a.timeCreated) - Date.parse(b.timeCreated));
    audiobooksSorted = audiobooksSorted.filter((book) => languageFilter === "any" || book.language === languageFilter);
    audiobooksSorted = audiobooksSorted.slice(0, Math.min(25, audiobooksSorted.length));

    console.log(languages);

    return (
        <div className="relative text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">{t("recentlyAddedAudiobook")}</h2>
            <div className="absolute top-0 right-2">
                <select
                    id="language-filter"
                    value={languageFilter}
                    onChange={(e) => setLanguageFilter(e.target.value)}
                    className={inputCls}
                >
                    {Array.from(languages).map((lang) => (
                        <option key={lang} value={lang}>
                            {t(lang as TranslationKey)}
                        </option>
                    ))}
                </select>
            </div>
            <div className="flex overflow-x-scroll scrollbar-hide">
                <div className="flex flex-nowrap mt-3 mb-6 mx-3 gap-5">
                    {audiobooksSorted.map((book: Audiobook) => {
                        return <NewBookCard key={book.id} book={book} />;
                    })}
                </div>
            </div>
        </div>
    );
}
