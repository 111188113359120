import { formatDecimals } from "@/utils/format";

export function PlaybackSpeedIcon({
    factor,
    className,
    onClick,
    title,
}: { factor?: number } & { className?: string; onClick?: (e: React.MouseEvent<SVGElement, MouseEvent>) => void; title?: string }) {
    return (
        <div className="relative inline-block">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                onClick={onClick}
                className={className ?? "size-6"}
            >
                {title && <title>{title}</title>}
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"
                />
            </svg>

            {/* The number badge */}
            {factor !== undefined && (
                <span
                    style={{
                        position: "absolute",
                        bottom: "-8px",
                        right: "-16px",
                        color: "inherit",
                        fontSize: "10px",
                        fontWeight: "bold",
                        borderRadius: "50%",
                        padding: "2px 4px",
                        minWidth: "16px",
                        textAlign: "center",
                        lineHeight: "1",
                    }}
                >
                    {formatDecimals(factor, 2)}x
                </span>
            )}
        </div>
    );
}
