import {
    Audiobook,
    AudiobookUserInfo,
    useLazyGetAudiobooksByIdUserInfoQuery,
    usePutAudiobooksByIdUserInfoMutation
} from "@/api/generatedApi";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {useImageHook} from "@/utils/imageHook";
import {setErrorNotification, setSuccessNotification} from "@/store/notificationSlice";
import {getErrorMessage} from "@/components/util/ErrorComponent";
import {isFinished} from "@/utils/audiobookUtils";
import {Card} from "@/components/Card";
import {Link} from "react-router-dom";
import {formatDuration, formatString} from "@/utils/util";
import {HeadphoneIcon} from "@/components/icons/ListenedIcon";

export function SeriesBookCard(props: { audiobook: Audiobook, info: AudiobookUserInfo | undefined }) {
    const dispatch = useDispatch()
    const {audiobook, info} = props
    const [userInfo, setUserInfo] = useState(info)

    const [fetchInfo, {data: newUserInfo}] = useLazyGetAudiobooksByIdUserInfoQuery()
    const [updateInfo, {data: updateUserInfoData, error: updateUserInfoError}] = usePutAudiobooksByIdUserInfoMutation()
    const imgSrc = useImageHook(audiobook.id, 128)

    useEffect(() => {
        if (updateUserInfoError === undefined) {
            return
        }
        dispatch(setErrorNotification({text: "Toggle Failed"}));
        getErrorMessage(updateUserInfoError);
    }, [updateUserInfoError])

    useEffect(() => {
        if (updateUserInfoData === undefined) {
            return
        }
        dispatch(setSuccessNotification({text: `Toggle ${audiobook.name} Finished`}));
        fetchInfo({id: audiobook.id})
    }, [updateUserInfoData])

    useEffect(() => {
        if (newUserInfo === undefined) {
            return
        }
        setUserInfo(newUserInfo)
    }, [newUserInfo])

    const audiobookFinished = userInfo ? isFinished(audiobook, userInfo) : false

    const narratorsText = audiobook.narrators.map((narrator) => narrator.name).join(", ")

    function handleUpdateFinished(e: React.MouseEvent<SVGElement, MouseEvent>) {
        e.stopPropagation()

        updateInfo({
            id: audiobook.id,
            updateAudiobookUserInfo: {
                progressMSec: audiobookFinished ? 0 : audiobook.lengthMsec,
                lastListenedTo: new Date().toISOString()
            }
        })
    }

    return (
        <Card className={`my-3 ml-1  flex justify-items-start hover:ring-gray-400 hover:ring-2`}>
            <div className="shrink-0">
                <Link to={`/audiobooks/${audiobook.id}`}>
                    <img className="h-32 w-32 rounded-l" src={imgSrc} alt="Icon"/>
                </Link>
            </div>

            <div className="ml-5 py-2 min-w-0">
                <Link className="text-xl leading-tight hover:underline" to={`/audiobooks/${audiobook.id}`}>
                    {formatString(audiobook.name, 25)}
                </Link>
                <p className="truncate">{formatString(narratorsText, 25)}</p>
                <p className="truncate">{formatDuration(audiobook.lengthMsec)}</p>
            </div>

            <div className="flex shrink-0 mt-2 mr-2 grow justify-end">
                {audiobookFinished ? (
                    <HeadphoneIcon.WithCheckmark
                        className="size-6 transform transition-all duration-300 scale-100 opacity-100 hover:scale-110 hover:opacity-80"
                        onClick={handleUpdateFinished}
                    />
                ) : (
                    <HeadphoneIcon.WithCross
                        className="size-6 transform transition-all duration-300 scale-100 opacity-100 hover:scale-110 hover:opacity-80"
                        onClick={handleUpdateFinished}
                    />
                )}
            </div>
        </Card>
    )
}