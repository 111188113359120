import React, {useMemo, useState} from "react";
import type {ReactNode} from "react";
import {DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import type {Active, UniqueIdentifier} from "@dnd-kit/core";
import {SortableContext, arrayMove, sortableKeyboardCoordinates} from "@dnd-kit/sortable";
import {DragHandle, SortableItem} from "@/components/SortableItem";
import {SortableOverlay} from "@/components/SortableOverlay";

interface BaseItem {
    id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
    items: T[];

    onChange(items: T[]): void;
    getIdxFunc: (item: T, idx: number) => boolean;
    renderItem(item: T): ReactNode;
}

export function SortableList<T extends BaseItem>({items, onChange, getIdxFunc, renderItem}: Props<T>) {
    const [active, setActive] = useState<Active | null>(null);
    const activeItem = useMemo(
        () => items.find((item) => item.id === active?.id),
        [active, items]
    );
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    return (
        <DndContext
            sensors={sensors}
            onDragStart={({active}) => {
                console.log("onDragStart", active)
                setActive(active);
            }}
            onDragEnd={({active, over}) => {
                console.log("over", over)

                if (over && active.id !== over?.id) {
                    const activeIndex = items.findIndex((ele) => getIdxFunc(ele, parseInt(active.id.toString())));
                    const overIndex = items.findIndex((ele) => getIdxFunc(ele, parseInt(over.id.toString())));
                    onChange(arrayMove(items, activeIndex, overIndex));

                }
                setActive(null);
            }}
            onDragCancel={() => {
                setActive(null);
            }}
        >
            <SortableContext items={items}>
                <ul className="SortableList" role="application">
                    {items.map((item) => (
                        <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
                    ))}
                </ul>
            </SortableContext>
            <SortableOverlay>
                {activeItem ? renderItem(activeItem) : null}
            </SortableOverlay>
        </DndContext>
    );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
