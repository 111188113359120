import { Audiobook, AudiobookUserInfo, useLazyGetAudiobooksByIdUserInfoQuery, usePutAudiobooksByIdUserInfoMutation } from "@/api/generatedApi";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useImageHook } from "@/utils/imageHook";
import { setErrorNotification, setSuccessNotification } from "@/store/notificationSlice";
import { getErrorMessage } from "@/components/util/ErrorComponent";
import { isFinished } from "@/utils/audiobookUtils";
import { useNavigate } from "react-router-dom";
import { formatDuration, formatString } from "@/utils/util";
import { Card } from "@/components/Card";
import { CopyableParagraph } from "@/components/CopyParagraph";
import { useApiEffect } from "@/utils/useApiEffect";
import { HideIcon } from "@/components/icons/HideIcon";
import { ShowIcon } from "@/components/icons/ShowIcon";
import { HeadphoneIcon } from "@/components/icons/ListenedIcon";

export function AudiobookCard(props: { audiobook: Audiobook; info: AudiobookUserInfo | undefined }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { audiobook, info } = props;
    const [userInfo, setUserInfo] = useState(info);

    const [fetchInfo, { data: newUserInfo }] = useLazyGetAudiobooksByIdUserInfoQuery();
    const imgSrc = useImageHook(audiobook.id, 128);

    const [updateUserInfo, hasListenedResource] = usePutAudiobooksByIdUserInfoMutation();
    useApiEffect(hasListenedResource, {
        onOk: () => {
            dispatch(setSuccessNotification({ text: `Toggle ${audiobook.name} Finished` }));
            fetchInfo({ id: audiobook.id });
        },
        onErr: (err) => {
            dispatch(setErrorNotification({ text: "Toggle Failed" }));
            getErrorMessage(err);
        },
    });

    useEffect(() => {
        if (newUserInfo === undefined) {
            return;
        }
        setUserInfo(newUserInfo);
    }, [newUserInfo]);

    const authorsText = audiobook.authors.map((author) => author.name).join(", ");
    const narratorsText = audiobook.narrators.map((narrator) => narrator.name).join(", ");

    const audiobookFinished = userInfo ? isFinished(audiobook, userInfo) : false;
    const handleUpdateFinished = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.stopPropagation();

        updateUserInfo({
            id: audiobook.id,
            updateAudiobookUserInfo: {
                progressMSec: audiobookFinished ? 0 : audiobook.lengthMsec,
                lastListenedTo: new Date().toISOString(),
            },
        });
    };

    const handleShowHide = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.stopPropagation();

        updateUserInfo({
            id: audiobook.id,
            updateAudiobookUserInfo: { isInterested: !userInfo?.isInterested },
        });
    };

    return (
        <Card className={`flex justify-items-start hover:ring-gray-400 hover:ring-2 cursor-pointer`} onClick={() => navigate(`/audiobooks/${audiobook.id}`)}>
            <div className="shrink-0">
                <img className="h-36 w-36 rounded-l" src={imgSrc} />
            </div>

            <div className="ml-5 py-2 min-w-0">
                {formatString(audiobook.name, 40)}
                <CopyableParagraph className="truncate">{formatString(authorsText, 25)}</CopyableParagraph>
                <CopyableParagraph className="truncate italic">{formatString(narratorsText, 25)}</CopyableParagraph>
                <p className="truncate italic">{formatDuration(audiobook.lengthMsec)}</p>
            </div>

            <div className="flex shrink-0 mt-2 mx-2 grow items-end justify-start md:items-start md:justify-end gap-1 flex-col md:flex-row">
                {audiobookFinished ? (
                    <HeadphoneIcon.WithCheckmark
                        className="size-6 transform transition-all duration-300 scale-100 opacity-100 hover:scale-110 hover:opacity-80"
                        onClick={handleUpdateFinished}
                    />
                ) : (
                    <HeadphoneIcon.WithCross
                        className="size-6 transform transition-all duration-300 scale-100 opacity-100 hover:scale-110 hover:opacity-80"
                        onClick={handleUpdateFinished}
                    />
                )}

                {userInfo?.isInterested ? (
                    <HideIcon
                        title="hide this audiobook from your library"
                        className="size-6 transform transition-all duration-300 scale-100 opacity-100 hover:scale-110 hover:opacity-80"
                        onClick={handleShowHide}
                    />
                ) : (
                    <ShowIcon
                        title="show this audiobook in your library"
                        className="size-6 transform transition-all duration-300 scale-100 opacity-100 hover:scale-110 hover:opacity-80"
                        onClick={handleShowHide}
                    />
                )}
            </div>
        </Card>
    );
}
