import { usePostUsersByIdMutation, UserWithToken } from "@/api/generatedApi"
import useTranslation from "@/i18n"
import { currentLanguage, setLanguage } from "@/i18n/languageSlice"
import { setErrorNotification } from "@/store/notificationSlice"
import { selectUser, setLoggedInUser } from "@/store/userSlice"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getErrorMessage } from "./util/ErrorComponent"
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "@/utils/util"
import { NavLink } from "react-router-dom"
import ic_icon from "@/img/icon.png"
import {LanguageIcon} from "@/components/icons/LanguageIcon";
import {SunIcon} from "@/components/icons/SunIcon";
import {MoonIcon} from "@/components/icons/MoonIcon";

function LanguageSelect() {
    const dispatch = useDispatch()
    const t = useTranslation()
    const lang = useSelector(currentLanguage)
    const loggedInUser = useSelector(selectUser)!!

    const [updateUser, { error }] = usePostUsersByIdMutation()
    useEffect(() => {
        if (error) {
            dispatch(setErrorNotification({ text: t(getErrorMessage(error)) }));
            return;
        }
    }, [error])

    return <Menu as="div" className="relative inline-block text-left">
        <div>
            <Menu.Button
                className="inline-flex text-sm px-4 py-2 leading-none border rounded border-white hover:border-transparent hover:text-indigo-500 hover:bg-white lg:mt-0 justify-center">
                <LanguageIcon className="size-3.5" />
            </Menu.Button>
        </div>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items
                className="absolute right-0 z-10 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                    <Menu.Item>
                        {({ active }) => (
                            <span
                                className={classNames(
                                    active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                                    lang === "english" ? "text-blue-500" : "",
                                    'block px-4 py-2 text-sm')}
                                onClick={() => {
                                    dispatch(setLanguage("english"))
                                    updateUser({ updateUser: { language: "english" }, id: loggedInUser.id })
                                }}>
                                {t("english")}
                            </span>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <span
                                className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    lang === "german" ? "text-blue-500" : "",
                                    'block px-4 py-2 text-sm')}
                                onClick={() => {
                                    dispatch(setLanguage("german"))
                                    updateUser({ updateUser: { language: "german" }, id: loggedInUser.id })
                                }}>
                                {t("german")}
                            </span>
                        )}
                    </Menu.Item>
                </div>
            </Menu.Items>
        </Transition>
    </Menu>
}

const Logo = () => {
    return (
        <NavLink to={`/`}>
            <div className="flex items-center flex-shrink-0 text-white mr-6">
                <img className="p-2" src={ic_icon} alt={"app icon"} width="48px" />
                <span className="font-semibold text-xl tracking-tight">AudioPort</span>
            </div>
        </NavLink>
    )
}

function MenuButton(props: { toggleMenu: () => void }) {
    return (
        <button
            className="flex items-center px-3 py-2 border rounded text-indigo-200 border-white hover:text-white hover:border-white"
            onClick={() => props.toggleMenu()}>
            <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
        </button>
    )
}

function DarkModeToggleButton() {
    const [darkMode, setDarkMode] = useState(localStorage.getItem("theme") === 'dark')

    useEffect(() => {
        updateDarkMode(darkMode)
        storeDarkMode(darkMode)
    }, [darkMode])

    const updateDarkMode = function (dark: boolean) {
        if (dark) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }

    const storeDarkMode = function (dark: boolean) {
        if (dark) {
            localStorage.setItem("theme", "dark")
        } else {
            localStorage.removeItem("theme")
        }
    }

    return (
        <button
            className="block text-sm mr-2 px-4 py-1.25 leading-none border rounded border-white hover:border-transparent hover:text-indigo-500 hover:bg-white lg:mt-0"
            onClick={() => setDarkMode(!darkMode)}
        >
            {darkMode ? <SunIcon className="size-5" /> : <MoonIcon className="size-5" />}
        </button>
    )
}

function MobileNavigation() {
    const dispatch = useDispatch()
    const t = useTranslation()

    const loggedInUser: UserWithToken = useSelector(selectUser)!!

    const navLinkActiveCls = "border-b-2 border-white text-lg"
    const navLinkCls = "block mt-4 mr-2 hover:text-gray-300"

    const [menuOpen, setMenuOpen] = useState(false)
    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (Math.abs(currentScrollY - lastScrollY) < 25) {
                return;
            }

            if (currentScrollY === 0) {
                // Always show navbar at the very top
                setShowNavbar(menuOpen || true);
            } else if (currentScrollY > lastScrollY) {
                // Hide navbar when scrolling down
                setShowNavbar(menuOpen || false);
            } else if (currentScrollY < lastScrollY) {
                // Show navbar when scrolling up
                setShowNavbar(menuOpen || true);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY, menuOpen]);


    return <div className="h-12">
        <nav
            className={classNames(
                `flex items-center justify-between flex-wrap bg-sky-800 h-min shadow-md shadow-gray-400 dark:shadow-gray-900 fixed w-full z-10`,
                showNavbar ? 'block' : 'hidden'
            )}>
            <div className="w-full flex items-center justify-between flex-wrap pr-2">
                <Logo />
                <MenuButton toggleMenu={() => setMenuOpen(!menuOpen)} />
            </div>
            <div className={classNames(
                'pb-3 w-full text-white bg-sky-800/95 rounded-b fixed right-0 top-12 grow transform origin-top transition-all duration-200',
                menuOpen ? 'opacity-100 scale-y-100' : 'opacity-0 scale-y-0 pointer-events-none'
            )}>
                <div className="text-sm flex flex-col items-end pr-4">
                    <NavLink to={`/`}
                        className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                        onClick={() => setMenuOpen(false)}>
                        {t("navBarHome")}
                    </NavLink>

                    <NavLink to={`/audiobooks`}
                        className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                        onClick={() => setMenuOpen(false)}>
                        {t("navBarLibrary")}
                    </NavLink>

                    <NavLink to={`/series`}
                        className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                        onClick={() => setMenuOpen(false)}>
                        {t("navBarSeries")}
                    </NavLink>

                    <NavLink to={`/want-to-listen`}
                             className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                             onClick={() => setMenuOpen(false)}>
                        {t("navBarWantToListen")}
                    </NavLink>

                    <NavLink to={`/history`}
                        className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                        onClick={() => setMenuOpen(false)}>
                        {t("navBarHistory")}
                    </NavLink>

                    <NavLink to={`/statistics`}
                        className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                        onClick={() => setMenuOpen(false)}>
                        {t("navBarStatistics")}
                    </NavLink>

                    <NavLink to={`/profile`}
                        className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                        onClick={() => setMenuOpen(false)}>
                        {t("navBarProfile")}
                    </NavLink>
                    {loggedInUser.isAdmin &&
                        <NavLink to={`/admin`}
                            className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                            onClick={() => setMenuOpen(false)}>
                            {t("navBarAdmin")}
                        </NavLink>
                    }
                </div>
                <div className="flex items-end justify-end pl-4 pr-4 pt-2">
                    <DarkModeToggleButton />
                    <LanguageSelect />
                    <button
                        className="block text-sm ml-2 px-4 py-2 leading-none border rounded border-white hover:border-transparent hover:text-indigo-500 hover:bg-white lg:mt-0"
                        onClick={() => dispatch(setLoggedInUser(null))}>
                        {t("navBarLogout")}
                    </button>
                </div>
            </div>
        </nav>
    </div>
}

function DesktopNavigation() {
    const dispatch = useDispatch()
    const t = useTranslation()
    const loggedInUser: UserWithToken = useSelector(selectUser)!!

    const navLinkActiveCls = "border-b-2 border-white text-lg"
    const navLinkCls = "block mt-4 mb-3 inline-block mt-0 mr-4 hover:text-gray-300"

    return <nav
        className={`flex items-center justify-between flex-wrap bg-sky-800 h-min shadow-md shadow-gray-400 dark:shadow-gray-900 w-full`}>
        <div className="w-auto flex items-center justify-between flex-wrap p-2">
            <Logo />
        </div>
        <div className='text-white rounded-b right-0 top-20 static flex items-center grow w-auto'>
            <div className="text-sm flex items-end pr-0 grow">
                <NavLink to={`/`}
                    className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}>
                    {t("navBarHome")}
                </NavLink>

                <NavLink to={`/audiobooks`}
                    className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}>
                    {t("navBarLibrary")}
                </NavLink>

                <NavLink to={`/series`}
                    className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                >
                    {t("navBarSeries")}
                </NavLink>

                <NavLink to={`/want-to-listen`}
                    className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                >
                    {t("navBarWantToListen")}
                </NavLink>

                <NavLink to={`/history`}
                    className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                >
                    {t("navBarHistory")}
                </NavLink>

                <NavLink to={`/statistics`}
                    className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                >
                    {t("navBarStatistics")}
                </NavLink>

                <NavLink to={`/profile`}
                    className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                >
                    {t("navBarProfile")}
                </NavLink>
                {loggedInUser.isAdmin &&
                    <NavLink to={`/admin`}
                        className={({ isActive }) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                    >
                        {t("navBarAdmin")}
                    </NavLink>
                }
            </div>
            <div className="flex items-end justify-end pr-4 pt-2 pl-0">
                <DarkModeToggleButton />
                <LanguageSelect />
                <button
                    className="block text-sm ml-2 px-4 py-2 leading-none border rounded border-white hover:border-transparent hover:text-indigo-500 hover:bg-white lg:mt-0"
                    onClick={() => dispatch(setLoggedInUser(null))}>
                    {t("navBarLogout")}
                </button>
            </div>
        </div>
    </nav>
}

export function Navigation() {
    return <>
        <div className="block lg:hidden">
            <MobileNavigation />
        </div>
        <div className="hidden lg:block">
            <DesktopNavigation />
        </div>
    </>
}
