import { useEffect } from "react";
import { createBrowserRouter, NavLink, Outlet, RouterProvider } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";

import { DataNotFound } from "@/views/ErrorView";
import AudiobookList from "@/views/audiobook/Audiobooks";
import LoginView from "@/views/LoginView";
import SeriesList from "@/views/series/Series";
import { AudiobookDetailsView } from "@/views/audiobook/Audiobook";
import { ProfileView } from "@/views/Profile";
import { Language } from "@/i18n";
import { AdminView } from "@/views/admin/AdminView";
import { selectUser, setLoggedInUser, userKey } from "@/store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { setNotification } from "@/store/notificationSlice";
import FooterComponent from "@/components/FooterComponent";
import { setLanguage } from "@/i18n/languageSlice";
import {
    ErrorNotificationComponent,
    InfoNotificationComponent,
    LoadingNotificationComponent,
    SuccessNotificationComponent,
} from "@/components/util/NotificationComponent";
import SeriesDetailsView from "@/views/series/SeriesDetails";
import { SeriesEditor } from "@/views/series/SeriesEditor";
import { StatisticsView } from "@/views/statistics/StatisticsView";
import { AudiobookEditor } from "./views/audiobook/AudiobookEditor";
import { Navigation } from "./components/Navigation";
import History from "@/views/history/History";
import HomeView from "@/views/home/HomeView";
import WantToListenView from "@/views/want-to-listen/WantToListenView";

export default function App() {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(selectUser);

    useEffect(() => {
        if (loggedInUser) dispatch(setLanguage(loggedInUser.language as Language));
    }, [loggedInUser]);

    // on load check if we find user information in local storage
    useEffect(() => {
        const serializedUser = localStorage.getItem(userKey);
        if (serializedUser) dispatch(setLoggedInUser(JSON.parse(serializedUser)));
    }, []);

    // notification stuff
    const notification = useSelector((state: RootState) => state.notification.value);
    // remove notification after it is eased out
    useEffect(() => {
        if (!notification) return;
        const timeId = setTimeout(() => dispatch(setNotification(null)), notification.duration + 500);
        return () => clearTimeout(timeId);
    }, [notification]);
    // decide what to render - notification wise
    let notificationComponent = null;
    if (notification !== null) {
        switch (notification.type) {
            case "success":
                notificationComponent = <SuccessNotificationComponent title={notification.title} text={notification.text} duration={notification.duration} />;
                break;
            case "error":
                notificationComponent = <ErrorNotificationComponent title={notification.title} text={notification.text} duration={notification.duration} />;
                break;
            case "info":
                notificationComponent = <InfoNotificationComponent title={notification.title} text={notification.text} duration={notification.duration} />;
                break;
            case "loading":
                notificationComponent = <LoadingNotificationComponent title={notification.title} text={notification.text} duration={notification.duration} />;
                break;
        }
    }

    const router = createBrowserRouter([
        {
            path: "/",
            element: <RootElement />,
            errorElement: <DataNotFound description="not found" />,
            children: [
                { path: "", element: <HomeView /> },
                { path: "audiobooks", element: <AudiobookList /> },
                { path: "series", element: <SeriesList /> },
                { path: "audiobooks/:audiobookId", element: <AudiobookDetailsView /> },
                { path: "audiobooks/:audiobookId/edit", element: <AudiobookEditor /> },
                { path: "audiobooks/add", element: <AudiobookEditor /> },
                { path: "series/:seriesId", element: <SeriesDetailsView /> },
                { path: "series/:seriesId/edit", element: <SeriesEditor /> },
                { path: "series/add", element: <SeriesEditor /> },
                { path: "want-to-listen", element: <WantToListenView /> },
                { path: "history", element: <History /> },
                { path: "statistics", element: <StatisticsView /> },
                { path: "profile", element: <ProfileView /> },
                { path: "admin", element: <AdminView /> },
            ],
        },
    ]);

    if (!loggedInUser) {
        return (
            <div className="h-screen">
                {notificationComponent}
                <LoginView />
            </div>
        );
    }

    return (
        <div className="h-screen">
            {notificationComponent}
            <RouterProvider router={router} />
        </div>
    );
}

function RootElement() {
    return (
        <div className="bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-200 min-h-screen flex flex-col">
            <Navigation />
            <div className={"flex-grow w-full text-gray-700 dark:text-white transition"}>
                <Outlet />
            </div>
            <FooterComponent />
            <ScrollRestoration
                getKey={(location, matches) => {
                    return location.pathname;
                }}
            />
        </div>
    );
}
