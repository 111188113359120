import { Fragment, ReactElement, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import ic_cross from "../../img/cross.png";
import ic_check from "../../img/checkmark.png";
import { CheckCircleIcon } from "@/components/icons/CheckCircleIcon";
import { CrossCircleIcon } from "@/components/icons/CrossCircleIcon";
import { LoadingIcon } from "@/components/icons/LoadingIcon";

export function ErrorNotificationComponent(props: { title: string; text: string; duration: number }) {
    return (
        <NotificationComponent
            title={props.title}
            text={props.text}
            duration={props.duration}
            icon={<img className="h-6 w-6" src={ic_cross} aria-hidden="true" alt="cross" />}
        />
    );
}

export function InfoNotificationComponent(props: { title: string; text: string; duration: number }) {
    return (
        <NotificationComponent
            title={props.title}
            text={props.text}
            duration={props.duration}
            icon={<img className="h-6 w-6" src={ic_check} aria-hidden="true" alt="check" />}
        />
    );
}

export function LoadingNotificationComponent(props: { title: string; text: string; duration: number }) {
    return (
        <NotificationComponent
            title={props.title}
            text={props.text}
            duration={props.duration}
            icon={<LoadingIcon className="text-blue-400 size-6" aria-hidden="true" />}
        />
    );
}

export function SuccessNotificationComponent(props: { title: string; text: string; duration: number }) {
    return (
        <NotificationComponent
            title={props.title}
            text={props.text}
            duration={props.duration}
            icon={<CheckCircleIcon className="size-6 text-green-400" aria-hidden="true" />}
        />
    );
}

function NotificationComponent(props: { title: string; text: string; duration: number; icon: ReactElement }) {
    const [show, setShow] = useState(true);

    useEffect(() => {
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            setShow(false);
        }, props.duration);

        return () => {
            clearTimeout(timeId);
        };
    }, []);

    return (
        <>
            <div aria-live="assertive" className="fixed z-40 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
                <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                    <Transition
                        show={show}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="max-w-sm w-full bg-white dark:bg-slate-700 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">{props.icon}</div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900 dark:text-white">{props.title}</p>
                                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">{props.text}</p>
                                    </div>
                                    <div className="ml-4 flex-shrink-0 flex">
                                        <button
                                            className="rounded-md inline-flex text-gray-400 dark:text-gray-100"
                                            onClick={() => {
                                                setShow(false);
                                            }}
                                        >
                                            <CrossCircleIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
}
