import {useState} from "react";

export function HeadphoneIcon({
    className,
    onClick,
    title,
}: {
    className?: string;
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    title?: string;
}) {
    return (
        <svg
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            onClick={onClick}
            className={className ?? "size-5"}
        >
            {title && <title>{title}</title>}
            <path d="M16 48V32a16 16 0 0 1 32 0v16" strokeLinecap="round" />
            <rect x="8" y="32" width="8" height="16" rx="4" />
            <rect x="48" y="32" width="8" height="16" rx="4" />
        </svg>
    );
}
function HeadphoneIconStrikeThrough({
    className,
    onClick,
    title,
}: {
    className?: string;
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    title?: string;
}) {
    return (
        <svg
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            onClick={onClick}
            className={className ?? "size-5"}
        >
            {title && <title>{title}</title>}
            <path d="M16 48V32a16 16 0 0 1 32 0v16" strokeLinecap="round" />
            <rect x="8" y="32" width="8" height="16" rx="4" />
            <rect x="48" y="32" width="8" height="16" rx="4" />
            <line x1="14" y1="14" x2="54" y2="54" strokeLinecap="round" />
        </svg>
    );
}
function HeadphoneIconWithCheckmark({
    className,
    onClick,
    title,
}: {
    className?: string;
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    title?: string;
}) {
    return (
        <svg
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            onClick={onClick}
            className={className ?? "size-5"}
        >
            {title && <title>{title}</title>}
            <path d="M16 48V32a16 16 0 0 1 32 0v16" strokeLinecap="round" />
            <rect x="8" y="32" width="8" height="16" rx="4" />
            <rect x="48" y="32" width="8" height="16" rx="4" />
            <polyline points="44,8 50,16 60,4" strokeWidth="5" stroke="#4CBB17" fill="none" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

function HeadphoneWithCross({
    className,
    onClick,
    title,
}: {
    className?: string;
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    title?: string;
}) {
    const [darkMode, setDarkMode] = useState(localStorage.getItem("theme") === 'dark')

    const fillColor = darkMode ? "black" : "white";

    return (
        <svg
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            onClick={onClick}
            className={className ?? "size-5"}
        >
            {title && <title>{title}</title>}

            {/* Cross */}
            <line x1="44" y1="6" x2="60" y2="22" stroke="red" strokeWidth="4" strokeLinecap="round" />
            <line x1="60" y1="6" x2="44" y2="22" stroke="red" strokeWidth="4" strokeLinecap="round" />

            {/* Headband */}
            <path d="M16 48V32a16 16 0 0 1 32 0v16" strokeLinecap="round" />

            {/* Left Earcup */}
            <rect x="8" y="32" width="8" height="16" rx="4" />

            {/* Right Earcup */}
            <rect x="48" y="32" width="8" height="16" rx="4" />
        </svg>
    );
}

HeadphoneIcon.StrikeThrough = HeadphoneIconStrikeThrough;
HeadphoneIcon.WithCheckmark = HeadphoneIconWithCheckmark;
HeadphoneIcon.WithCross = HeadphoneWithCross;
